import Vue from "vue";

class AuthService {
  static login(email, password) {
    return Vue.auth.login({
      data: { email, password },
      staySignedIn: true,
      fetchUser: true,
      redirect: "/dashboard",
    });
  }
}

export default AuthService;
