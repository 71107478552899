<template>
  <b-row class="row justify-content-center">
    <b-col lg="5" md="7">
      <b-card bg-variant="secondary" class="shadow border-0">
        <b-card-body class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <span>Sign in with credentials</span>
          </div>
          <VeeObserver ref="form" slim>
            <VeeProvider name="email" rules="required|email" v-slot="{ errors }">
              <base-input
                class="input-group-alternative"
                placeholder="E-mail"
                addon-left-icon="ni ni-email-83"
                v-model="payload.email"
                :error="errors[0]"
              />
            </VeeProvider>
            <VeeProvider name="password" rules="required" v-slot="{ errors }">
              <base-input
                class="input-group-alternative"
                placeholder="Password"
                type="password"
                addon-left-icon="ni ni-lock-circle-open"
                v-model="payload.password"
                :error="errors[0]"
              />
            </VeeProvider>
            <div class="text-center">
              <base-button
                variant="primary"
                type="submit"
                class="my-4"
                @click="login()"
                :disabled="loading"
              >
                <b-spinner v-if="loading" small />
                <span v-else> Sign in </span>
              </base-button>
            </div>
          </VeeObserver>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import AuthService from "features/auth/services/AuthService";

export default {
  name: "Auth",
  data: () => ({
    loading: false,
    payload: {
      email: "",
      password: "",
    },
  }),
  methods: {
    async login() {
      try {
        if (await this.$refs.form.validate()) {
          this.loading = true;
          await AuthService.login(this.payload.email, this.payload.password);
        }
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Authentication failed",
          text: "Check the data entered and try again",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style></style>
